import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function Slider() {
    const [currentKeyvisual, setCurrentKeyvisual] = React.useState(1);
    const keyvisualLength = 2;
    React.useEffect(() => {
        const interval = setInterval(() => {
            const current = currentKeyvisual + 1
            current > keyvisualLength ? setCurrentKeyvisual(1) : setCurrentKeyvisual(current)
        }, 6000);
        return () => clearInterval(interval);
    }, [currentKeyvisual])

    return (
        <>
            <ol className="p-slider is-pc">
                <li className={`is-01${currentKeyvisual === 1 ? ' is-current' : ''}${currentKeyvisual === 2 ? ' is-prev' : ''}`}>
                    <div className="p-slider__left">
                        <div>
                            <span><StaticImage src="../../assets/img/pages/top/keyvisual_right_img01.png" alt="" placeholder="blurred" /></span>
                            <span><StaticImage src="../../assets/img/pages/top/keyvisual_right_img01.png" alt="" placeholder="blurred" /></span>
                            <span><StaticImage src="../../assets/img/pages/top/keyvisual_right_img01.png" alt="" placeholder="blurred" /></span>
                        </div>
                    </div>
                    <div className="p-slider__center">
                        <div>
                            <span><StaticImage src="../../assets/img/pages/top/keyvisual_center_img01.png" alt="" placeholder="blurred" /></span>
                            <span><StaticImage src="../../assets/img/pages/top/keyvisual_center_img01.png" alt="" placeholder="blurred" /></span>
                            <span><StaticImage src="../../assets/img/pages/top/keyvisual_center_img01.png" alt="" placeholder="blurred" /></span>
                        </div>
                    </div>
                    <div className="p-slider__right">
                        <div>
                            <span><StaticImage src="../../assets/img/pages/top/keyvisual_left_img01.png" alt="" placeholder="blurred" /></span>
                            <span><StaticImage src="../../assets/img/pages/top/keyvisual_left_img01.png" alt="" placeholder="blurred" /></span>
                            <span><StaticImage src="../../assets/img/pages/top/keyvisual_left_img01.png" alt="" placeholder="blurred" /></span>
                        </div>
                    </div>
                </li>
                <li className={`is-02${currentKeyvisual === 2 ? ' is-current' : ''}${currentKeyvisual === 1 ? ' is-prev' : ''}`}>
                    <div className="p-slider__left">
                        <div>
                            <span><StaticImage src="../../assets/img/pages/top/keyvisual_left_img02.png" alt="" placeholder="blurred" /></span>
                            <span><StaticImage src="../../assets/img/pages/top/keyvisual_left_img02.png" alt="" placeholder="blurred" /></span>
                            <span><StaticImage src="../../assets/img/pages/top/keyvisual_left_img02.png" alt="" placeholder="blurred" /></span>
                        </div>
                    </div>
                    <div className="p-slider__center">
                        <div>
                            <span><StaticImage src="../../assets/img/pages/top/keyvisual_center_img02.png" alt="" placeholder="blurred" /></span>
                            <span><StaticImage src="../../assets/img/pages/top/keyvisual_center_img02.png" alt="" placeholder="blurred" /></span>
                            <span><StaticImage src="../../assets/img/pages/top/keyvisual_center_img02.png" alt="" placeholder="blurred" /></span>
                        </div>
                    </div>
                    <div className="p-slider__right">
                        <div>
                            <span><StaticImage src="../../assets/img/pages/top/keyvisual_right_img02.png" alt="" placeholder="blurred" /></span>
                            <span><StaticImage src="../../assets/img/pages/top/keyvisual_right_img02.png" alt="" placeholder="blurred" /></span>
                            <span><StaticImage src="../../assets/img/pages/top/keyvisual_right_img02.png" alt="" placeholder="blurred" /></span>
                        </div>
                    </div>
                </li>
                {/* <li className={`is-03${currentKeyvisual === 3 ? ' is-current' : ''}${currentKeyvisual === 1 ? ' is-prev' : ''}`}>
                    <div className="p-slider__left">
                        <div>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    <div className="p-slider__center">
                        <div>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    <div className="p-slider__right">
                        <div>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </li> */}
            </ol>

            <ol className="p-slider is-sp">
                <li className={`is-01${currentKeyvisual === 1 ? ' is-current' : ''}${currentKeyvisual === 2 ? ' is-prev' : ''}`}>
                    <div className="p-slider__left">
                        <div>
                            <span><StaticImage src="../../assets/img/pages/top/keyvisual_right_img01-sp.png" alt="" placeholder="blurred" /></span>
                            <span><StaticImage src="../../assets/img/pages/top/keyvisual_right_img01-sp.png" alt="" placeholder="blurred" /></span>
                            <span><StaticImage src="../../assets/img/pages/top/keyvisual_right_img01-sp.png" alt="" placeholder="blurred" /></span>

                        </div>
                    </div>
                    <div className="p-slider__center">
                        <div>
                            <span><StaticImage src="../../assets/img/pages/top/keyvisual_center_img01-sp.png" alt="" placeholder="blurred" /></span>
                            <span><StaticImage src="../../assets/img/pages/top/keyvisual_center_img01-sp.png" alt="" placeholder="blurred" /></span>
                            <span><StaticImage src="../../assets/img/pages/top/keyvisual_center_img01-sp.png" alt="" placeholder="blurred" /></span>
                        </div>
                    </div>
                    <div className="p-slider__right">
                        <div>
                            <span><StaticImage src="../../assets/img/pages/top/keyvisual_left_img01-sp.png" alt="" placeholder="blurred" /></span>
                            <span><StaticImage src="../../assets/img/pages/top/keyvisual_left_img01-sp.png" alt="" placeholder="blurred" /></span>
                            <span><StaticImage src="../../assets/img/pages/top/keyvisual_left_img01-sp.png" alt="" placeholder="blurred" /></span>
                        </div>
                    </div>
                </li>
                <li className={`is-02${currentKeyvisual === 2 ? ' is-current' : ''}${currentKeyvisual === 1 ? ' is-prev' : ''}`}>
                    <div className="p-slider__left">
                        <div>
                            <span><StaticImage src="../../assets/img/pages/top/keyvisual_left_img02-sp.png" alt="" placeholder="blurred" /></span>
                            <span><StaticImage src="../../assets/img/pages/top/keyvisual_left_img02-sp.png" alt="" placeholder="blurred" /></span>
                            <span><StaticImage src="../../assets/img/pages/top/keyvisual_left_img02-sp.png" alt="" placeholder="blurred" /></span>
                        </div>
                    </div>
                    <div className="p-slider__center">
                        <div>
                            <span><StaticImage src="../../assets/img/pages/top/keyvisual_center_img02-sp.png" alt="" placeholder="blurred" /></span>
                            <span><StaticImage src="../../assets/img/pages/top/keyvisual_center_img02-sp.png" alt="" placeholder="blurred" /></span>
                            <span><StaticImage src="../../assets/img/pages/top/keyvisual_center_img02-sp.png" alt="" placeholder="blurred" /></span>
                        </div>
                    </div>
                    <div className="p-slider__right">
                        <div>
                            <span><StaticImage src="../../assets/img/pages/top/keyvisual_right_img02-sp.png" alt="" placeholder="blurred" /></span>
                            <span><StaticImage src="../../assets/img/pages/top/keyvisual_right_img02-sp.png" alt="" placeholder="blurred" /></span>
                            <span><StaticImage src="../../assets/img/pages/top/keyvisual_right_img02-sp.png" alt="" placeholder="blurred" /></span>
                        </div>
                    </div>
                </li>
            </ol>
        </>
    )
}
