import React from "react"
import Button from "src/component/common/button"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { ImgixGatsbyImage } from '@imgix/gatsby';
import { useStaticQuery, graphql } from 'gatsby'

// ID変換関数
const convertId = (id) => id.replace(/_/g, "-");

export default function CasestudyListLatest3posts({ data }) {
    data = useStaticQuery(
        graphql`
            query {
                allMicrocmsCasestudy(
                    limit: 3
                    sort: { fields: display_date, order: DESC }
                ) {
                    edges {
                        node {
                            casestudyId
                            company_name
                            display_date(formatString: "YYYY-MM-DD")
                            id
                            title
                            ai_services_category {
                                id
                                name
                            }
                            type_category {
                                id
                                name
                            }
                            uses_category {
                                id
                                name
                            }
                            casestudy_thumbnail {
                                url
                                width
                                height
                            }
                            contents
                        }
                    }
                }
            }
        `
    )
    


    return (
        <>
            <ol className="c-casestudy-list-latest-3posts">
                {data.allMicrocmsCasestudy.edges.map(({ node }) => (
                    <li key={node.id}>
                        <Link to={`/casestudy/${convertId(node.casestudyId)}`}>
                            {node.casestudy_thumbnail ? (
                                <div className="c-casestudy-list-latest-3posts__image">
                                    <ImgixGatsbyImage
                                        src={node.casestudy_thumbnail.url}
                                        imgixParams={{ auto: ['format', 'compress'] }}
                                        layout="fullWidth"
                                        style={{ height: "100%" }}
                                        alt={node.title}
                                        sourceWidth={node.casestudy_thumbnail.width}
                                        sourceHeight={node.casestudy_thumbnail.height}
                                    />
                                </div>
                            ) : (
                                <div className="c-casestudy-list-latest-3posts__image is-default">
                                    <StaticImage
                                        src="../../assets/img/pages/top/casestudy_img01.png"
                                        alt={node.title}
                                        placeholder="blurred"
                                    />
                                </div>
                            )}
                            <div className="c-casestudy-list-latest-3posts__text">
                                {node.company_name && (
                                    <p className="c-casestudy-list-latest-3posts__company">{node.company_name}</p>
                                )}
                                <h3 className="c-casestudy-list-latest-3posts__title">{node.title}</h3>
                                {(node.ai_services_category || node.type_category || node.uses_category) && (
                                    <ul className="c-casestudy-list-latest-3posts__tag">
                                        {node.ai_services_category &&
                                            node.ai_services_category.map((category) => (
                                                <li key={category.id}>{category.name}</li>
                                            ))}
                                        {node.type_category &&
                                            node.type_category.map((category) => (
                                                <li key={category.id}>{category.name}</li>
                                            ))}
                                        {node.uses_category &&
                                            node.uses_category.map((category) => (
                                                <li key={category.id}>{category.name}</li>
                                            ))}
                                    </ul>
                                )}
                                <div className="c-casestudy-list-latest-3posts__button">
                                    <Button className="c-button01" label="詳しく見る" />
                                </div>
                            </div>
                        </Link>
                    </li>
                ))}
            </ol>
        </>
    )
}

