import React from "react"
import { Link } from "gatsby"
import Layout from "src/component/layout/layout"
import Button from "src/component/common/button"
import CasestudyListLatest3posts from "src/component/list/casestudyListLatest3posts"
import NewsListLatest1posts from "src/component/list/newsListLatest1posts"
import NewsListLatest3posts from "src/component/list/newsListLatest3posts"
import ClientListLatest12posts from "src/component/list/clientListLatest12posts"
import Slider from "src/component/common/slider"
import { Seo } from "src/component/common/seo"
import { StaticImage, GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"
import { graphql } from 'gatsby'
import AiPlatformLogo from '../assets/img/pages/top/logo_ai_platform.svg';
import AiModelsLogo from '../assets/img/pages/top/logo_ai_models.svg';
import AiAgentsLogo from '../assets/img/pages/top/logo_ai_agents.svg';

export default function Home({ data }) {
    const chartImgPath = withArtDirection(getImage(data.chartImg.childImageSharp), [
        {
            media: "(max-width: 768px)",
            image: getImage(data.about01Sp.childImageSharp),
        },
    ])
    const chartImgPathSp = withArtDirection(getImage(data.chartImgSp.childImageSharp), [
        {
            media: "(max-width: 768px)",
            image: getImage(data.chartImgSp.childImageSharp),
        },
    ])
    const aiSeriesPlatformImgPath = withArtDirection(getImage(data.productAirlakePc.childImageSharp), [
        {
            image: getImage(data.productAirlakeSp.childImageSharp),
        },
    ])
    const productBecozImgPath = getImage(data.productBecozPc.childImageSharp)
    const productPerswellImgPath = withArtDirection(getImage(data.productPerswellPc.childImageSharp), [
        {
            image: getImage(data.productPerswellSp.childImageSharp),
        },
    ])
    React.useEffect(() => {
        let js_scroll = document.getElementsByClassName('js-scroll');
        const scroll = () => {
            for (let i = 0; i < js_scroll.length; i++) {
                if ((window.pageYOffset + (window.innerHeight / 1.5)) > (window.pageYOffset + js_scroll[i].getBoundingClientRect().top)) {
                    js_scroll[i].classList.add('is-active');
                }
            }
        };
        scroll();
        window.addEventListener('scroll', () => {
            scroll();
        })
    }, [])
    return (
        <>
            <Layout isTop>
                <div className="p-keyvisual">
                    <div className="p-keyvisual__inner c-inner-primary">
                        <div className="p-keyvisual__content">
                            <div className="p-keyvisual__background pc-show">
                                <Slider />
                            </div>

                            <div className="p-keyvisual__title js-scroll pc-show-fl">
                                <div className="is-01"><p><span>データを商いに。</span></p></div>
                                <div className="is-02"><p><span>Data Science for every business.</span></p></div>
                                <div className="is-03"><p><span>企業と社会の未来を、<br className="c-sp" />データサイエンスの力で創造する</span></p></div>
                            </div>


                            <svg width="0" height="0">
                              <defs>
                                <clipPath id="mv-mask">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M823.139 319C787.028 319 787.029 290.637 787.029 281.496C787.029 262.695 770.862 247.455 750.918 247.455H679.826C659.882 247.455 643.715 232.215 643.715 213.414V68.0908C643.715 49.29 627.548 34.0498 607.605 34.0498H36.1106C16.167 34.0498 0 18.8096 0 0.00878906V319H823.139Z" fill="#D9D9D9"/>
                                </clipPath>
                              </defs>
                            </svg>

                            <div className="p-keyvisual__background sp-show">
                                <Slider />
                            </div>
                        
                            
                            {/* <FormTop /> */}

                            <div className="keyvisual-title-sp js-scroll sp-show">
                                <div className="keyvisual-title-sp__wrap">
                                 <div className="is-01"><p><span>データを<br />商いに。</span></p></div>
                                 <div className="is-02"><p><span>Data Science for<br /> every business.</span></p></div>
                                </div>
                                <div className="is-03"><p><span>企業と社会の未来を、<br className="c-sp" />データサイエンスの力で創造する</span></p></div>
                            </div>

                            <svg width="0" height="0">
                              <defs>
                                <clipPath id="mv-mask-sp" clipPathUnits="userSpaceOnUse">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1050 281.515C1049.93 313.906 1022.18 340.134 987.942 340.134H812.544C778.265 340.134 750.486 299.055 750.486 266.61C750.486 234.165 722.708 207.874 688.429 207.874H537.419C503.139 207.874 475.361 181.582 475.361 149.137V117.473C475.361 85.0283 447.583 58.7366 413.303 58.7366H62.0576C27.7784 58.7366 0 32.4447 0 0V718.927H1050V281.515Z" fill="#D9D9D9"/>
                                </clipPath>
                              </defs>
                            </svg>




                            <div className="p-keyvisual__news pc-show">
                                <NewsListLatest1posts />
                                <div className="p-keyvisual__news__button c-pc"><Button path="/search" className="c-button01 is-white" label="お知らせ一覧を見る" /></div>
                                <div className="p-keyvisual__news__button c-sp"><Button path="/search" className="c-button01" label="お知らせ一覧を見る" /></div>
                            </div>
                        </div>
                    </div>
                </div>


                
                <div className="p-keyvisual__news sp-show">
                    <NewsListLatest1posts />
                    <div className="p-keyvisual__news__button c-pc"><Button path="/search" className="c-button01 is-white" label="お知らせ一覧を見る" /></div>
                    <div className="p-keyvisual__news__button c-sp"><Button path="/search" className="c-button01" label="お知らせ一覧を見る" /></div>
                </div>
                
                <section className="p-top-about01">
                    <div className="p-top-about01__inner c-inner-primary">
                        <div className="p-top-about01__image pc-show">
                            <GatsbyImage
                                image={chartImgPath}
                                className="is-gatsby"
                                alt="特徴のイメージその1"
                            />
                        </div>
                        <div className="p-top-about01__image sp-show">
                            <GatsbyImage
                                image={chartImgPathSp}
                                className="is-gatsby"
                                alt="特徴のイメージその1"
                            />
                        </div>
                        <div className="p-top-about01__text">
                            <h2 className="p-top-about01__title js-scroll">
                                <div><div><span>複雑な業務課題を解決する<br />マルチモーダルAIエージェント</span></div></div>
                            
                            </h2>
                            <p className="p-top-about01__lead">
                                Airlakeは、
                                非構造化データをノーコードで構造化・活用するAIプラットフォーム、業界別の高精度な分析を可能にするAIモデル、自社業務を理解し自律的にタスクを実行するAIエージェントを提供。
                                <br />
                                <br />
                                紙の書類、PDF、画像、音声など、あらゆるデータを最新のAI技術と組み合わせて活用し、生産性を飛躍的に向上。高度なAIをノーコードで実務に組み込み、新たな可能性を生み出します。
                                </p>
                            <div className="p-top-about01__button">
                                <Button path="/company" className="c-button01 is-white" label="DATAFLUCTとは" />
                            </div>
                        </div>
                    </div>
                </section>


                <section className="p-top-ai-service">
                    <div className="p-top-ai-service__inner c-inner-primary">
                        <h2 className="p-top-ai-service__title">AI SERVICE</h2>

                        <section className="p-top-ai-service__item">
                            <div className="p-top-ai-service__item__content is-text-left">
                                <div className="p-top-ai-service__item__image">
                                    <h3 className="p-top-ai-service__item__subtitle pc-show">
                                        <span className="is-airlake">
                                            <img src={AiPlatformLogo} alt="AI Platform" placeholder="blurred" />
                                        </span>
                                    </h3>

                                    <GatsbyImage
                                        image={aiSeriesPlatformImgPath}
                                        className="is-airlake"
                                        alt="AI Platform（エーアイプラットフォーム）のイメージ"
                                    />
                                      <div className="p-top-ai-service__item__button pc-show">
                                        <Button path="/ai-service/ai-platform" className="c-button01" label="詳しく見る" />
                                      </div>
                                </div>

                                <div className="p-top-ai-service__item__title-sp sp-show-fl">
                                    <h3 className="p-top-ai-service__item__titlesp">
                                        <span className="is-airlake">
                                            <StaticImage
                                                src="../assets/img/pages/top/logo_ai_platform.svg"
                                                alt="AI Platform"
                                                placeholder="blurred"
                                            
                                            />
                                        </span>
                                    </h3>
                                      <div className="p-top-ai-service__item__button">
                                        <Button path="/ai-service/ai-platform" className="c-button01" label="詳しく見る" />
                                      </div>
                                </div>

                                <div className="p-top-ai-service__item__text">
                                  
                                    <p className="p-top-ai-service__item__lead">非構造化データを資産に変えるAI基盤で、データ活用を加速します。</p>

                                    <p className="p-top-ai-service__item__lead">AIが文書、音声、画像、IoTデータを自動で構造化します。BIや生成AIと連携し、高度なデータ活用をノーコードで実現。クラウド型で迅速導入が可能です。</p>
                                    <p className="p-top-ai-service__item__lead">AIエージェントによる業務の自律化を実現するための基盤として、全社的なDXを強力に推進します。</p>
                                </div>
                            </div>
                        </section>

                        <section className="p-top-ai-service__item">
                            <div className="p-top-ai-service__item__content is-text-right">
                                <div className="p-top-ai-service__item__image">
                                    <h3 className="p-top-ai-service__item__subtitle pc-show">
                                        <span className="is-becoz">
                                            <img src={AiModelsLogo} alt="AI Platform" placeholder="blurred" />
                                        </span>
                                    </h3>
                                    <GatsbyImage
                                        image={productBecozImgPath}
                                        className="is-becoz"
                                        alt="AI Models（エーアイモデルス）のイメージ"
                                    />
                                    <div className="p-top-ai-service__item__button pc-show">
                                        <Button path="/ai-service/ai-models" className="c-button01" label="詳しく見る" />
                                    </div>
                                </div>

                                <div className="p-top-ai-service__item__title-sp sp-show-fl">
                                    <h3 className="p-top-ai-service__item__titlesp">
                                        <span className="is-airlake">
                                            <StaticImage
                                                src="../assets/img/pages/top/logo_ai_models.svg"
                                                alt="AI Models"
                                                placeholder="blurred"
                                            />
                                        </span>
                                    </h3>
                                      <div className="p-top-ai-service__item__button">
                                        <Button path="/ai-service/ai-models" className="c-button01" label="詳しく見る" />
                                      </div>
                                </div>
                                
                                <div className="p-top-ai-service__item__text">
                                    <p className="p-top-ai-service__item__lead">高精度な予測と最適化を実現するAIサービス。</p>
                                    <p className="p-top-ai-service__item__lead">従来のツールでは対応が難しかった 複雑なビジネス課題に対し、オーダーメイドのAIモデルを開発します。</p>
                                    <p className="p-top-ai-service__item__lead">多様なデータを活用し、本番環境でも高い精度を維持する予測モデルを開発可能。業務プロセスの高度化と効率化を支援します。</p>
                               
                                </div>

                            </div>
                        </section>

                        <section className="p-top-ai-service__item">

                            <div className="p-top-ai-service__item__content is-text-right sp-rev">
                                <div className="p-top-ai-service__item__text">
                                    <p className="p-top-ai-service__item__lead">対話型AIがデータ分析を自動化し、業務効率を加速します。</p>
                                    <p className="p-top-ai-service__item__lead">AIが対話を通じてデータ分析を行い、グラフや報告書を自動生成します。大量のデータをリアルタイムに処理し、分析業務の効率化と品質向上を実現します。</p>
                                    <p className="p-top-ai-service__item__lead">ナレッジ共有や属人化の課題を解決し、質の高いインサイトを迅速に提供します。</p>
                                </div>

                                <div className="p-top-ai-service__item__title-sp sp-show-fl">
                                    <h3 className="p-top-ai-service__item__titlesp">
                                        <span className="is-airlake">
                                            <StaticImage
                                                src="../assets/img/pages/top/logo_ai_agents.svg"
                                                alt="AI Agents"
                                                placeholder="blurred"
                                            />
                                        </span>
                                    </h3>
                                      <div className="p-top-ai-service__item__button">
                                        <Button  path="/ai-service/ai-agents" className="c-button01" label="詳しく見る" />
                                      </div>
                                </div>

                                <div className="p-top-ai-service__item__image">
                                     <h3 className="p-top-ai-service__item__subtitle pc-show">
                                        <span className="is-perswell">
                                            <img src={AiAgentsLogo} alt="AI Agents" placeholder="blurred" />
                                        </span>
                                    </h3>
                                    <GatsbyImage
                                        image={productPerswellImgPath}
                                        className="is-perswell"
                                        alt="AI Agents（エーアイエージェント）のイメージ"
                                    />
                                    <div className="p-top-ai-service__item__button pc-show">
                                        <Button path="/ai-service/ai-agents" className="c-button01" label="詳しく見る" />
                                    </div>
                                </div>
                            </div>
                        </section>
                   
                    </div>
                </section>

         

            
                <section className="p-top-casestudy">
                    <div className="p-top-casestudy__inner c-inner-primary">
                        <div className="p-top-casestudy__title c-title01">
                            <h2 className="p-top-casestudy__title__text c-title01__text">導入事例</h2>
                            {/* <Button path="/search/?pc=case_study" className="p-top-casestudy__title__button c-title01__button c-button01" label="すべての事例を見る" /> */}
                        </div>
                        <CasestudyListLatest3posts />
                    </div>
                </section>

                <section className="p-top-tags">
                    <div className="p-top-tags__inner c-inner-primary">
                        <div className="p-top-tags__content">
                            <div className="p-top-tags__title c-title01">
                                <h2 className="p-top-client__title__text c-title01__text sp-pd">事例検索</h2>
                            </div>
                            <div className="p-top-tags__wrapper">
                                <div className="p-top-tags__wrapper__tagcloud">
                                 <h3 className="p-top-tags__wrapper__title">業界から探す</h3>
                                  <div className="p-top-tags__wrapper__tags">
                                    <ul>
                                        <li className="industries_tag">
                                            <Link to={"/industries/food"}>食品</Link>
                                        </li>
                                        <li className="industries_tag">
                                            <Link to={"/industries/retail"}>卸売/小売</Link>
                                        </li>
                                        <li className="industries_tag">
                                            <Link to={"/industries/public"}>公共/運輸</Link>
                                        </li>
                                        <li className="industries_tag">
                                            <Link to={"/industries/construction"}>建設/不動産</Link>
                                        </li>
                                        <li className="industries_tag">
                                            <Link to={"/industries/manufacturing"}>製造業</Link>
                                        </li>
                                        <li className="industries_tag">
                                            <Link to={"/industries/finance"}>金融/IT/サービス</Link>
                                        </li>
                                    </ul>
                                   </div>

                                </div>
                                <hr className="underline" />
                                <div className="p-top-tags__wrapper__tagcloud">
                                 <h3 className="p-top-tags__wrapper__title">事例から探す</h3>
                                  <div className="p-top-tags__wrapper__tags">
                                    <ul>
                                        <li className="case_studies">
                                            <Link to={"/casestudies/data-structuring"}>データ構造化</Link>
                                        </li>
                                        <li className="case_studies">
                                            <Link to={"/casestudies/predictive-ai"}>予測AI</Link>
                                        </li>
                                        <li className="case_studies">
                                             <Link to={"/casestudies/analytical-ai"}>分析AI</Link>
                                        </li>
                                        <li className="case_studies">
                                            <Link to={"/casestudies/optimization-ai"}>最適化AI</Link>
                                        </li>
                                        <li className="case_studies">
                                            <Link to={"/casestudies/gx-solutions"}>GXソリューション</Link>
                                        </li>
                                        <li className="case_studies">
                                            <Link to={"/casestudies/geospatial-data"}>地理空間データ</Link>
                                        </li>
                                    </ul>
                                   </div>

                                </div>
                           
                            </div>
                        </div>
                    </div>
                </section>


                <section className="p-top-client">
                    <div className="p-top-client__inner c-inner-primary">
                        <div className="p-top-client__title c-title01">
                            <h2 className="p-top-client__title__text c-title01__text">取引先企業</h2>
                            <Button path="/company#client" className="p-top-client__title__button c-title01__button c-button01" label="取引実績一覧を見る" />
                            <small className="p-top-client__title__annotation c-title01__annotation">※ 取引企業様の一部を、順不同でご紹介しております。</small>
                        </div>
                        <ClientListLatest12posts />
                    </div>
                </section>
           
                <section className="p-top-news">
                    <div className="p-top-news__inner c-inner-primary">
                        <div className="p-top-news__title c-title01">
                            <h2 className="p-top-news__title__text c-title01__text">お知らせ</h2>
                            <Button path="/search" className="p-top-news__title__button c-title01__button c-button01 latest-updates-button" label="お知らせ一覧を見る" />
                        </div>
                        <NewsListLatest3posts />
                    </div>
                </section>
            </Layout>
        </>
    )
}

export function Head({ data }) {
    const schema = {
        "@context": "http://schema.org",
        "@type": "WebSite",
        "url": data.site.siteMetadata.productionSiteUrl,
        "name": data.microcmsSetting.site_title,
        "publisher": {
            "@type": "Organization",
            "name": data.microcmsSetting.site_title,
            "url": data.site.siteMetadata.productionSiteUrl,
            "logo": {
                "@type": "ImageObject",
                "url": data.site.siteMetadata.productionSiteUrl + "img/common/microdata_logo.png"
            },
            "address": {
                "@type": "PostalAddress",
                "postalCode": "150-0043",
                "addressRegion": "東京都",
                "addressLocality": "渋谷区",
                "streetAddress": "道玄坂一丁目19番9号 第一暁ビル6階"
            }
        },
        "image": {
            "@type": "ImageObject",
            "url": data.site.siteMetadata.productionSiteUrl + "img/common/microdata_logo.png"
        },
        "mainEntity": {
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [
                {
                    "@type": "ListItem",
                    "position": 1,
                    "item": {
                        "@id": data.site.siteMetadata.productionSiteUrl,
                        "name": "HOME"
                    }
                }
            ]
        }
    };
    const schemaAsString = JSON.stringify(schema, null, 2);
    return (
        <Seo
            title="TOP"
            description=""
            pathname=""
        >
            <script type="application/ld+json">{schemaAsString}</script>
        </Seo>
    )
}

export const query = graphql`
    query {
        site {
            siteMetadata {
                productionSiteUrl
            }
        }
        microcmsSetting {
            site_title
        }
        chartImg: file(relativePath: {eq: "pages/top/chart.png"}) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
        about01Sp: file(relativePath: {eq: "pages/top/about01_img01-sp.png"}) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
        chartImgSp: file(relativePath: {eq: "pages/top/chart-sp.png"}) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
        about02Sp: file(relativePath: {eq: "pages/top/about02_img01-sp.png"}) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
        productAirlakePc: file(relativePath: {eq: "pages/top/ai_series_platform.png"}) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
        productAirlakeSp: file(relativePath: {eq: "pages/top/ai_series_platform_sp.png"}) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
        productBecozPc: file(relativePath: {eq: "pages/top/ai_series_models.png"}) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
        productBecozSp: file(relativePath: {eq: "pages/top/ai_series_models_sp.png"}) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
        productPerswellPc: file(relativePath: {eq: "pages/top/ai_series_agents.png"}) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
        productPerswellSp: file(relativePath: {eq: "pages/top/ai_series_agents_sp.png"}) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
        productTownearPc: file(relativePath: {eq: "pages/top/product_townear_img.png"}) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
        productTownearSp: file(relativePath: {eq: "pages/top/product_townear_img-sp.png"}) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
    }
`
